import React from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    ImageField,
    List,
    Filter,
    NumberField,
    SingleFieldList,
    TextField,
    TextInput,
    FunctionField,
    Button
} from "react-admin";


import { Typography, Grid, Card, CardContent } from "@mui/material";

// Used for styling the row. Out of stock items should be highlighted
const postRowStyle = (record) => ({
    backgroundColor: 'inherit',
    border: record.stock_status !== 'instock' ? '1px dashed white' : '0px solid white',
    color: record.stock_status !== 'instock' ? 'grey' : 'inherit', // Change text color for out-of-stock
    opacity: record.stock_status !== 'instock' ? 0.7 : 1, // Reduce opacity for out-of-stock
    padding: 2,
    margin: 2,
});


const ProductFilter =  props => (
    <Filter {...props} perPage={50} variant="outlined">
        <TextInput label="Haku" source="q" alwaysOn resettable/>
        
    </Filter>
);

const NameHeader = () => (
    <Typography variant="h4">Kirjan nimi</Typography>
);

const AuthorHeader = () => (
    <Typography variant="h4">Tekijä</Typography>
);
const ShelfHeader = () => (
    <Typography variant="h4">Hylly</Typography>
);

const LayoutHeader = () => (
    <Typography variant="h4">Sidosasu</Typography>
);

const IsbnHeader = () => (
    <Typography variant="h4">ISBN</Typography>
);

const PriceHeader = () => (
    <Typography variant="h4">Hinta</Typography>
);

const QuantityHeader = () => (
    <Typography variant="h4">Määrä</Typography>
);

const DatePublishedHeader = () => (
    <Typography variant="h4">Julkaistu</Typography>
);

const DateModifiedHeader = () => (
    <Typography variant="h4">Päivitetty</Typography>
);

const ProductListView = (props) => {
    return(    
    <List 
    sort={{ field: 'date_modified', order: 'DESC' }}
    queryOptions={{ onSettled: (data, error) => console.log(data, error) }}
    filters={<ProductFilter />} 
    perPage={10}
    title="admin.kirjain.xyz :: Kirjat" 
    {...props}
    
    >

        <Datagrid optimized 
        rowClick="edit"
        bulkActionButtons={false}
        rowSx={postRowStyle}>
            <Card sx={{ alignItems: 'flex-start',  backgroundColor: 'white', border: 'inherit', padding: 0.1, margin: 0.1}}>
                <CardContent>
                <Grid 
                        container
                        spacing={1}
                        sx={{marginTop: 2, marginBottom: 1, marginLeft: 1, marginRight: 1}}
                        >
                    <Grid item>
                        <TextField label="ID" source="id" />
                    </Grid>
                    
                    <Grid item>
                        <ArrayField
                            label={false}
                            source="images">
                            <SingleFieldList >
                                <ImageField source="src" />
                            </SingleFieldList>
                        </ArrayField>   
                    </Grid>
                    <Grid item>
                    <Grid 
                    container
                    spacing={1}>
                        <Grid 
                        container
                        spacing={1}>
                            
                            <Grid item md={3}>
                                <NameHeader/>
                                <TextField label="Nimi" source="name" />
                            </Grid>
                            <Grid item md={3}>
                                <AuthorHeader/>
                                <TextField label="Tekijä" source="author" />
                            </Grid>
                            <Grid item md={2}>
                                <ShelfHeader/>
                                <TextField label="Hylly" source="shelf"/>
                            </Grid>
                            <Grid item md={2}>
                                <LayoutHeader/>
                                <TextField label="Sidosasu" source="layout"/>
                            </Grid>
                            <Grid item>
                        <DatePublishedHeader />
                        <DateField label="Julkaistu" source="date_created" />
                    </Grid>
                            
                        </Grid>
                        <Grid container spacing={1}>
                        <Grid item md={3}>
                                <IsbnHeader/>
                                <TextField label="ISBN" defaultValue="" source="isbn"/>
                            </Grid>
                            <Grid item md={3}>
                                <FunctionField label="Varaston tila" render={
                                    record => record.stock_status === 'instock' ? 
                                    <Button size="large" variant="contained" color="success">Myynnissä</Button>: 
                                    <Button size="large" variant="contained" color="warning">Ei myynnissä</Button>
                                    }/>
                            </Grid>
                            <Grid item md={2}>
                                <PriceHeader />
                                <NumberField
                                    label="Hinta"
                                    source="price"
                                    locales="fi-FI"
                                    options={{ style: 'currency', currency: 'EUR' }}/>
                            </Grid>
                            <Grid item md={2}>
                                <QuantityHeader />
                                <TextField label="Määrä" source="stock_quantity"
                                />
                            </Grid>
                            <Grid item>
                        <DateModifiedHeader />
                        <DateField label="Päivitetty" source="date_modified" />
                    </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                    </Grid>
                </CardContent>
               </Card> 
        </Datagrid>
    </List>
)
};

export default ProductListView;