// in src/App.js
import { Route } from 'react-router-dom';
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import fbDataProvider from './api/fb-dataProvider';
import { OrderDetailView } from './views/OrderDetailView';
import { ProductCreateView } from './views/ProductCreateView';
import OrderPdfView from './views/OrderPdfView';
import ProductListView from "./views/ProductListView";
import { BatchListView } from "./views/BatchListView";
import { BatchProductCreateView } from "./views/BatchProductCreateView";
import { BatchProductEditView } from "./views/BatchProductEditView";
import { OrderListView } from "./views/OrderListView";
import { Book } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import finnishMessages from 'ra-language-finnish';
import { FirebaseAuthProvider } from "react-admin-firebase";
import MyLayout from './components/MyLayout';
import CustomLoginPage from './pages/LoginPage';
import { firebaseConfig as config } from './FIREBASE_CONFIG';
import { ProductEditView } from "./views/ProductEditView";
import PaperbaseTheme from "./themes/PaperbaseTheme";
import { AntikkaProductCreateView } from "./views/AntikkaProductCreateView";
import { AntikkaProductEditView } from "./views/AntikkaProductEditView";
import { AntikkaBatchProductCreateView } from "./views/AntikkaBatchProductCreateView";
import { AntikkaBatchProductEditView } from "./views/AntikkaBatchProductEditView";
import { BatchReportList } from "./views/BatchReportList";
import { SalesReportList } from "./views/SalesReportList";
import { UserRoleProvider, UserRoleContext } from './UserRoleContext';
import { QueryClient, QueryClientProvider } from 'react-query';

const options = {
  logging: true,
  rootRef: 'products/kirjapino',
}

const i18nProvider = polyglotI18nProvider(() => finnishMessages, 'fi');
const dataProvider = fbDataProvider;
const authProvider = FirebaseAuthProvider(config, options);

const vendorRoles = ['vendor', 'kirjapinoVendor', 'antikkaVendor', 'admin'];
const antikkaRoles = ['antikkaVendor', 'antikkaEditor'];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1 * 60 * 1000, // 5 minutes
        },
    },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserRoleProvider>
        <UserRoleContext.Consumer>
          {({ role, vendor }) => (
            <Admin 
              theme={PaperbaseTheme}
              layout={MyLayout}
              loginPage={CustomLoginPage} 
              title="admin.kirjain.xyz"
              i18nProvider={i18nProvider} 
              dataProvider={dataProvider} 
              authProvider={authProvider}
              customRoutes={[
                <Route
                  path="/orders/:id/print"
                  element={<OrderPdfView />}
                  key="print"
                />,
              ]}
            >
              {(vendorRoles.includes(role)) &&
                <Resource 
                  options={{ label: 'Tilaukset'}} 
                  name="orders"
                  list={OrderListView}
                  edit={OrderDetailView}
                  show={OrderPdfView}
                /> 
              }
              {(role === 'vendor' || role === 'admin') &&
                <Resource 
                  options={{ label: 'Kirjat' }} 
                  name="products"
                  list={ProductListView}
                  edit={ProductEditView}
                  create={ProductCreateView}
                  icon={Book}
                />
              }
              {(role === 'antikkaVendor') &&
                <Resource 
                  options={{ label: 'Antikka Kirjat' }} 
                  name="products"
                  list={ProductListView}
                  edit={AntikkaProductEditView}
                  create={AntikkaProductCreateView}
                  icon={Book}
                />
              }
              {(!antikkaRoles.includes(role)) &&
                <Resource 
                  options={{ label: 'Eräajo' }} 
                  name="batch"
                  list={BatchListView}
                  edit={BatchProductEditView}
                  create={BatchProductCreateView}
                  icon={Book}
                />
              }
              {(antikkaRoles.includes(role)) &&
                <Resource 
                  options={{ label: 'Eräajo' }} 
                  name="batch"
                  list={BatchListView}
                  edit={AntikkaBatchProductEditView}
                  create={AntikkaBatchProductCreateView}
                  icon={Book}
                />
              }
              {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&
                <Resource 
                  options={{ label: 'Eräajon Raportit' }} 
                  name="reports"
                  list={BatchReportList}
                  icon={SummarizeIcon}
                />
              }
              {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&
                <Resource 
                  options={{ label: 'Myynnit' }} 
                  name="sales"
                  list={SalesReportList}
                  icon={MonetizationOnIcon}
                />
              }
            </Admin>
          )}
        </UserRoleContext.Consumer>
      </UserRoleProvider>
    </QueryClientProvider>
  );
};

export default App;